@import "./assets/scss/font-awesome";
@import "./assets/scss/bootstrap";
@import "./assets/scss/icoicon";
@import "./assets/scss/flag-icon";
@import "./assets/scss/themify";
@import "./assets/scss/theme/user-profile";
@import "./assets/scss/style";
@import "./assets/scss/responsive";
@import "./assets/scss/todo";
@import "./assets/scss/chartist";
@import "./assets/scss/whether-icon";
@import "./assets/scss/whether-icon/whether-icon";
//@import "~slick-carousel/slick/slick.css";
//@import "~slick-carousel/slick/slick-theme.css";
